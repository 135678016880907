import { Injectable } from "@angular/core";
import {
    DataService, DialogService, ReportService, RoutingService,
    UserService, AppSettings, WebSocketService, Tables, TipNotificare,
} from "core";

@Injectable({ providedIn: 'root' })
export class NotificationService {
    constructor(private router: RoutingService, user: UserService,
        private data: DataService, private settings: AppSettings,
        private dialog: DialogService, private ws: WebSocketService,
        private report: ReportService
    ) {
        user.onLoginComplete.subscribe((user) => { if (user) this.listen() });
        // this.listen();
    }

    private list: any[] = [];
    get hasNew() { return this.list.some(n => !n.dataVizualizare) }

    getNotifications(onlyNew?: boolean) {
        if (onlyNew) { return this.list.filter(n => !n.dataVizualizare) }
        return this.list
    }

    private listen() {
        // .send because listen to all events not just notification
        var sub = this.ws.send('notification').subscribe({
            next: (item) => {
                if (item.event == 'notification') {
                    const notif: any = item['message'];
                    if (Array.isArray(notif.list) && notif.replace) {
                        this.list = notif.list
                    } else if (notif.actiune == 'refresh') {
                        this.dialog.confirm({
                            title: 'Actualizare', confirmBtn: 'Refresh',
                            message: 'Aplicatia a fost actualizata,\n pentru a functiona corect va rugam sa faceti refresh la browser!',
                        }).then(() => this.setViewed(notif))
                            .then(() => this.action(notif))
                            .catch((error) => console.error(error));
                    } else {
                        this.list.unshift(notif);
                    }
                } else {

                }
            },
            error: (error) => {
                sub && sub.unsubscribe();
                this.listen();
            }
        })
    }

    getSettings() {

    }

    setViewed(item/*: metadata.log.Notificare & metadata.log.NotificareUser */, isDismiss?: boolean) {
        return Promise.resolve()
        // return this.data.getEntityByKeyNew<metadata.log.NotificareUser>(Tables.log.NotificareUser, item['idNotificareUser']).then(n => {
        //     if (isDismiss) {
        //         n[0].entityAspect.setDeleted();
        //     } else {
        //         const stamp = new Date();
        //         item.dataVizualizare = stamp;
        //         n[0].dataVizualizare = stamp;
        //     }
        //     return this.data.saveChanges(n).then(() => {
        //         isDismiss && this.list.splice(this.list.indexOf(item), 1)
        //     });
        // })
    }
    action(item/*: metadata.log.Notificare & metadata.log.NotificareUser */) {
        switch (item.idTipNotificare) {
            case TipNotificare.link: this.router.navigate([item.actiune]); break;
            case TipNotificare.report: this.report.open(item.actiune); break;
            case TipNotificare.update: window.location.reload(); break;
        }
    }
}