import { Injectable } from '@angular/core';
import { UserService } from './UserService';
import { ConfigService } from './ConfigService';
import { Config } from '../constants/Enum';

const userCssKey = 'user.css';
const userFormKey = 'user.editForm';
const cssTheme = getClassNameForKey('theme');
const gridTheme = getClassNameForKey('grid-theme');

interface UserCss {
  theme: string, agTheme: string, homeApps: 'list' | 'tabs',
  agType: 'balham' | 'alpine', navbarBg: string, controlsBg: string,
  navbarType: 'navbar-light' | 'navbar-dark', navbarPosition: '' | 'fixed-left' | 'fixed-right',
  controlsType: 'navbar-light' | 'navbar-dark', controlsPosition: '' | 'fixed-left' | 'fixed-right',
  controlsSize: 'sm' | 'lg' | '', isActiveAnimation: boolean
}

@Injectable({ providedIn: 'root' })
export class StyleManager {
  constructor(user: UserService, private config: ConfigService) {
    let userFormOptions = JSON.parse(localStorage.getItem(userFormKey));
    if (userFormOptions) {
      this.editForm.isCenter = userFormOptions.isCenter;
      this.editForm.isCompact = userFormOptions.isCompact;
      this.editForm.size = userFormOptions.zise;
    }
    // let userCssOptions = JSON.parse(localStorage.getItem(userCssKey));
    // if (userCssOptions) { // set from storage if exists until data from db arrives
    //   this.css = this.applyStyles(userCssOptions)
    // }

    this.checkIsMobile();
    user.onLoginComplete.subscribe(crtUser => {
      if (crtUser) {
        config.get(Config.theme$Administraresistem$Administraresistem).then(res => {
          let setare;
          try {
            setare = JSON.parse(res[Config.theme$Administraresistem$Administraresistem])
          } catch (error) { setare = res[Config.theme$Administraresistem$Administraresistem] }
          if (setare) {
            this.applyStyles(setare)
          }
        })
      }
    })
  }

  isMobile: boolean = false;
  css: UserCss = {
    theme: 'cerulean', agTheme: 'ag-theme-balham', agType: 'balham', homeApps: 'list',
    navbarBg: 'bg-light', navbarType: 'navbar-light', navbarPosition: '',
    controlsBg: 'bg-light', controlsType: 'navbar-light', controlsPosition: '',
    isActiveAnimation: true, controlsSize: ''
  };
  editForm = {
    size: '',
    get isSizeSm() { return this.size === 'sm' },
    set isSizeSm(val: boolean) {
      this.size = val ? 'sm' : '';
      localStorage.setItem(userFormKey, JSON.stringify(this));
    },
    get isCompact() { return this.compact },
    set isCompact(val: boolean) {
      this.compact = val;
      localStorage.setItem(userFormKey, JSON.stringify(this));
    },
    get isCenter() { return this.center },
    set isCenter(val: boolean) {
      this.center = val;
      localStorage.setItem(userFormKey, JSON.stringify(this));
    },
  };

  private applyStyles(setare: string | UserCss) {
    if (typeof setare === 'string') {
      let t: string;
      if (setare.indexOf('/bootswatch/') > -1) {
        let s = setare.split('/');
        s.pop();
        t = s.pop();
      } else { t = setare }

      if (setare != this.css.theme) { this.setTheme(t) }
      this.saveConfig();
    } else if (setare.theme) {
      if (setare.theme != this.css.theme) {
        this.setTheme(setare.theme)
      }
      this.setUserStyle(setare)
    }
    // this.storeCss()
  }
  private setUserStyle(userCss: UserCss) {
    this.setGridTheme(userCss.agType);
    this.css.navbarType = userCss.navbarType;
    this.css.controlsType = userCss.controlsType;
    this.css.navbarBg = userCss.navbarBg;
    this.css.controlsBg = userCss.controlsBg;
    this.css.controlsSize = userCss.controlsSize;
    this.css.navbarPosition = userCss.navbarPosition;
    this.css.controlsPosition = userCss.controlsPosition;
    this.css.isActiveAnimation = userCss.isActiveAnimation;
    this.css.homeApps = userCss.homeApps || 'list';
    // this.setBodyMargin();
  }

  checkIsMobile() {
    if (window.screen.width === 360) { // 768px portrait
      this.isMobile = true
    } else if (document.documentElement.clientWidth < 992) {
      this.isMobile = true
    }
    window.onresize = () => {
      this.isMobile = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 991
    }
  }

  setBodyMargin() {
    const navClass = this.css.navbarPosition, navMargin = '130px';
    const cmdClass = this.css.controlsPosition, cmdMargin = '170px';

    document.body.removeAttribute('style');
    if (navClass === 'fixed-left') {
      document.body.style.marginLeft = navMargin
    } else if (navClass === 'fixed-right') {
      document.body.style.marginRight = navMargin
    }

    if (cmdClass === 'fixed-left') {
      document.body.style.marginLeft = cmdMargin
    } else if (cmdClass === 'fixed-right') {
      document.body.style.marginRight = cmdMargin
    }
  }

  clearCss() { localStorage.removeItem(userCssKey) }
  storeCss() { localStorage.setItem(userCssKey, JSON.stringify(this.css)) }
  saveConfig() { return this.config.set(Config.theme$Administraresistem$Administraresistem, JSON.stringify(this.css)) }

  setThemeOld(theme: string) {
    this.css.theme = theme;
    this.applyStyleLink('theme', `/theme.${this.css.theme}.css`);
  }
  setTheme(theme: string) {
    this.css.theme = theme;
    const before = document.head.querySelector<HTMLLinkElement>(`link[rel="stylesheet"].${gridTheme}`);
    const el = this.applyStyleLink('theme-temp', `/theme.${this.css.theme}.css`, before);
    el.onload = () => {
      this.removeStyle('theme');
      el.setAttribute('class', cssTheme);
    };
  }
  setGridTheme(type?: 'balham' | 'alpine') {
    if (type) { this.css.agType = type }
    let agTheme = `ag-theme-${this.css.agType}${(/supehero|slate|solar|darkly|cyborg/.test(this.css.theme) ? '-dark' : '')}`;
    this.css.agTheme = agTheme;
    this.applyStyleLink('grid-theme', `/grid.${agTheme}.css`);
  }
  applyThemeAndGrid() {
    this.applyStyleLink('theme', `/theme.${this.css.theme}.css`);
    this.applyStyleLink('grid-theme', `/grid.${this.css.agTheme}.css`);
  }

  private applyStyleLink(key: string, href: string, insertBefore?: HTMLElement) {
    const el = getLinkElementForKey(key, insertBefore);
    el.setAttribute('href', href);
    return el;
  }
  private removeStyle(key: string) {
    const existingLinkElement = getExistingLinkElementByKey(key);
    if (existingLinkElement) { document.head.removeChild(existingLinkElement) }
  }
}

function getClassNameForKey(key: string) { return `style-manager-${key}` }
function getLinkElementForKey(key: string, insertBefore?: HTMLElement) {
  return getExistingLinkElementByKey(key) || createLinkElementWithKey(key, insertBefore)
}
function getExistingLinkElementByKey(key: string) {
  return document.head.querySelector<HTMLLinkElement>(`link[rel="stylesheet"].${getClassNameForKey(key)}`)
}
function createLinkElementWithKey(key: string, insertBefore?: HTMLElement) {
  const linkEl = document.createElement('link');
  linkEl.setAttribute('rel', 'stylesheet');
  linkEl.setAttribute('type', 'text/css');
  linkEl.classList.add(getClassNameForKey(key));
  insertBefore ? document.head.insertBefore(linkEl, insertBefore) : document.head.appendChild(linkEl);
  return linkEl;
}